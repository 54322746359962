import { TaxIdType } from '@rediredi/types'
import { useStorage } from '@vueuse/core'

const DEFAULT_VALUE = {
  notes: '',
  personal: {
    name: '',
    email: '',
    taxId: '',
    taxIdType: TaxIdType.CPF,
    phone: {
      countryCode: '',
      number: '',
    },
  },
  delivery: {
    zipCode: '',
    addressLine1: '',
    streetNumber: '',
    addressComplement: '',
    neighborhood: '',
    city: '',
    stateOrRegionCode: '',
    stateOrRegion: '',
    municipality: '',
  },
}

export const useUserInfoStorage = () => {
  const globalStore = useGlobalStore()

  const userInfoStorage = useStorage(`STOREFRONT_USER_INFO::${globalStore.store.id}`, {
    ...DEFAULT_VALUE,
  })

  const empty = () => {
    userInfoStorage.value = { ...DEFAULT_VALUE }
  }

  return { userInfoStorage, empty }
}
