<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    color: 'primary' | 'secondary' | 'none' | 'success' | 'disabled'
    disabled?: boolean
    loading?: boolean
    link?: boolean
    fullWidth?: boolean
  }>(),
  {
    fullWidth: true,
  },
)

const buttonClasses = computed(() => {
  return {
    [`button--${props.color}`]: !props.disabled,
    'bg-gray-200 text-gray-300': props.disabled,
    'cursor-wait opacity-90': props.loading,
    'inline-flex': props.link,
    'bg-apple text-white': props.color === 'success',
    '!bg-gray-200 text-gray-300': props.color === 'disabled',
    'w-full': props.fullWidth,
    flex: !props.link,
  }
})
</script>

<template>
  <button
    class="button rounded-full gap-3 items-center justify-center"
    :class="buttonClasses"
    v-bind="$attrs"
    :disabled="props.disabled || props.loading"
  >
    <slot />

    <RTransition name="fade">
      <RIcon v-if="props.loading" name="svg-spinners:180-ring" size="18" />
    </RTransition>
  </button>
</template>

<style scoped>
.button {
  box-sizing: border-box;
}
.button--primary {
  background-color: var(--color-primary);
  color: var(--color-text);
}

.button--secondary {
  background-color: white;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
</style>
