import { IMoney, PromotionType } from '@rediredi/types'
import { useStorage } from '@vueuse/core'
import { DateTime } from 'luxon'
import type { CartItem, ShoppingCart, ValidatedShoppingCart } from '~/types/cart'
import { IStorefrontItem } from '~/types/item'

const STORAGE_KEY = 'STOREFRONT_CART'

export const useCartStore = defineStore('cart', {
  state: () => {
    const globalStore = useGlobalStore()
    const storeId = globalStore.store.id as string
    const cart = useStorage<ShoppingCart>(`${STORAGE_KEY}::${storeId}`, {
      updatedAt: DateTime.now().toISO(),
      items: [],
    })

    return {
      isOpen: false,
      cart,
    }
  },

  getters: {
    total: (state) => {
      const globalStore = useGlobalStore()
      let amount = 0

      state.cart.items.forEach((item) => {
        amount += (item?.price?.amount || 0) * item.quantity
      })

      return { amount, currency: globalStore.storeCurrency } as IMoney
    },
    totalV2: (state) => {
      const globalStore = useGlobalStore()
      let amount = 0

      state.cart.items.forEach((item) => {
        const price =
          item?.promotion && item?.promotion.type !== PromotionType.Label
            ? item?.promotionPrice
            : item?.price

        amount += (price?.amount || 0) * item.quantity
      })

      return { amount, currency: globalStore.storeCurrency } as IMoney
    },

    size: (state) => {
      let size = 0
      state.cart.items.forEach((item) => {
        size += item.quantity
      })

      return size
    },

    isItemInCart: (state) => {
      return (item: IStorefrontItem | CartItem) => state.cart.items.some((i) => i.id === item.id)
    },

    hasItemWithNoPrice: (state) => {
      return state.cart.items.some((item) => !item.price?.amount)
    },

    hasItemWithNoPriceV2: (state) => {
      return state.cart.items.some((item) => {
        const price =
          item?.promotion && item.promotion.type !== PromotionType.Label
            ? item?.promotionPrice
            : item?.price

        return !price?.amount
      })
    },

    isEmpty: (state) => state.cart.items.length === 0,
  },

  actions: {
    addItem(item: CartItem, quantity?: number) {
      const { event } = useEvent()
      const cartItem = this.cart.items.find((i) => i.variantId === item.variantId)
      const isAddingForFirstTime = this.isEmpty
      if (cartItem) {
        cartItem.quantity += quantity ?? 1
      } else {
        this.cart.items.push({ ...item, quantity: quantity ?? 1 })
      }

      if (isAddingForFirstTime) {
        setTimeout(this.open, 300)
      }

      event('add_to_cart', getAddToCartEventPayload(cartItem || item, this.cart.items))
      this.tickUpdatedAt()
    },

    removeItem(item: CartItem) {
      const { event } = useEvent()
      const cartItem = this.cart.items.find((i) => i.variantId === item.variantId)

      if (!cartItem) return

      if (cartItem.quantity === 1) {
        this.cart.items = this.cart.items.filter((item) => item.variantId !== cartItem.variantId)
      } else {
        cartItem.quantity = cartItem.quantity - 1
      }

      event('remove_from_cart', getRemoveFromCartEventPayload(cartItem, this.cart.items))
      this.tickUpdatedAt()
    },

    updateItemQuantity(item: CartItem, quantity: number) {
      const { event } = useEvent()
      const cartItem = this.cart.items.find((i) => i.variantId === item.variantId)

      if (!cartItem) return

      const isAdding = quantity > cartItem.quantity

      if (cartItem) {
        cartItem.quantity = quantity
      }

      event(
        isAdding ? 'add_to_cart' : 'remove_from_cart',
        getRemoveFromCartEventPayload(cartItem, this.cart.items),
      )

      this.tickUpdatedAt()
    },

    deleteItem(item: CartItem) {
      const { event } = useEvent()
      const cartItem = this.cart.items.find((i) => i.variantId === item.variantId)

      if (!cartItem) return

      this.cart.items = this.cart.items.filter((item) => item.variantId !== cartItem.variantId)

      event('remove_from_cart', getRemoveFromCartEventPayload(item, this.cart.items))
      this.tickUpdatedAt()
    },

    emptyCart() {
      const globalStore = useGlobalStore()
      const storeId = globalStore.store.id as string
      const cart = useStorage<ShoppingCart>(
        `${STORAGE_KEY}::${storeId}`,
        {
          updatedAt: DateTime.now().toISO(),
          items: [],
        },
        localStorage,
        { mergeDefaults: true },
      )

      cart.value.items = []

      // @ts-ignore next-line
      this.cart = cart
    },

    async validateCart() {
      const response = await useFetchWrapper<ValidatedShoppingCart>(
        '/inventory/storefront/items/cart',
        'POST',
        this.cart,
      )
      return response
    },
    async validateCartV2() {
      const cartPayload = {
        ...this.cart,
        items: this.cart.items.map((i) => ({
          ...i,
          price: {
            ...i.price,
            amount: i.price.amount || 0,
          },
        })),
      }
      const response = await useFetchWrapper<ValidatedShoppingCart>(
        '/inventory/storefront/items/v2/cart',
        'POST',
        cartPayload,
      )
      return response
    },
    async validateCartV3() {
      const cartPayload = {
        ...this.cart,
        items: this.cart.items.map((i) => {
          const price =
            i.promotion && i.promotion.type !== PromotionType.Label ? i.promotionPrice : i.price

          return {
            ...i,
            price: {
              ...price,
              amount: price?.amount || 0,
            },
          }
        }),
      }
      const response = await useFetchWrapper<ValidatedShoppingCart>(
        '/inventory/storefront/items/v2/cart',
        'POST',
        cartPayload,
      )
      return response
    },

    open() {
      const { event } = useEvent()
      this.isOpen = true
      event('view_cart', getViewCartEventPayload(this.cart.items, this.total))
    },

    close() {
      this.isOpen = false
    },

    toggle() {
      this.isOpen ? this.close() : this.open()
    },

    tickUpdatedAt() {
      this.cart.updatedAt = DateTime.now().toISO()
    },
  },

  hydrate(state, _) {
    const globalStore = useGlobalStore()
    const storeId = globalStore.store.id as string
    const cart = useStorage<ShoppingCart>(
      `${STORAGE_KEY}::${storeId}`,
      {
        updatedAt: DateTime.now().toISO(),
        items: [],
      },
      localStorage,
      { mergeDefaults: true },
    )

    // @ts-ignore next-line
    state.cart = cart
  },
})
